import React, { createContext, useEffect, useReducer } from "react"

import { navigate } from "gatsby"

import _ from "lodash"
import PropTypes from "prop-types"

import {
  toastMessage,
  TYPE_ERROR,
  TYPE_SUCCESS,
} from "../../components/UI/Toast"
import mainAxios, {
  setAuthToken,
  removeAuthToken,
  isAuthTokenEmpty,
} from "../../utils/axiosConfig"
import {
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  REGISTER_SUCCESS,
} from "../../utils/constants"
import { getUserObject } from "../../utils/methods"
import AuthenticationReducer from "./AuthenticationReducer"

const initialState = {
  email: "",
  error: [],
  isAuthenticated: null,
  isLoadingRequest: false,
  isUserSet: false,
  plans: [],
  selectedLocation: {},
  selectedPlan: "",
  user: {},
  userSelectedPlan: "",
}

export const AuthenticationContext = createContext(initialState)

export const AuthenticationProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AuthenticationReducer, initialState)

  const setError = (error) => {
    dispatch({
      payload: error,
      type: "SET_ERROR",
    })
  }

  useEffect(() => {
    checkIsAuthenticated()
    getLocation()
  }, [])

  window.addEventListener("storage", (e) => {
    if (e.key === "access_token" && e.oldValue && !e.newValue) {
      logOut()
    }
  })

  const ensureAuthToken = () => {
    if (isAuthTokenEmpty()) {
      const client = localStorage.getItem("client")
      const uid = localStorage.getItem("uid")
      const accessToken = localStorage.getItem("access-token")
      setAuthToken(client, uid, accessToken)
    }
  }

  const signUp = (userDetails) => {
    setIsLoadingRequest(true)

    mainAxios
      .post("/auth", userDetails)
      .then((response) => {
        setIsLoadingRequest(false)
        if (response.request.status !== 422) {
          const userData = getUserObject(response.data.data)
          dispatch({
            payload: userData,
            type: "LOGIN_USER",
          })
          dispatch({
            payload: userData.email,
            type: "SET_USER",
          })
          const { "access-token": accessToken, client, uid } = response.headers
          localStorage.setItem("user", JSON.stringify(userData))
          localStorage.setItem("client", client)
          localStorage.setItem("uid", uid)
          localStorage.setItem("access-token", accessToken)
          setAuthToken(client, uid, accessToken)
          navigate("/choose-plan")
          toastMessage(REGISTER_SUCCESS, TYPE_SUCCESS)
        }
      })
      .catch((error) => {
        setIsLoadingRequest(false)
        setError([error])
      })
  }

  const updateUser = (userDetails) => {
    setIsLoadingRequest(true)
    ensureAuthToken()

    if (checkIsAuthenticated()) {
      mainAxios
        .put("/users", userDetails)
        .then((response) => {
          setIsLoadingRequest(false)
          if (response.data) {
            const userData = getUserObject(response.data)
            localStorage.setItem("user", JSON.stringify(userData))
            dispatch({
              payload: userData,
              type: "UPDATE_USER",
            })
            toastMessage("Your details have been saved", TYPE_SUCCESS)

            const client = localStorage.getItem("client")
            const accessToken = localStorage.getItem("access-token")
            localStorage.setItem("uid", userData.uid)
            setAuthToken(client, userData.uid, accessToken)
          }
        })
        .catch((error) => {
          setIsLoadingRequest(false)
          setError([error])
        })
    }
  }

  const setLocation = (location) => {
    dispatch({ payload: location, type: "UPDATE_LOCATION" })
    localStorage.setItem("loc", JSON.stringify(location))
  }

  const getLocation = () => {
    if (!_.isEmpty(state.selectedLocation)) {
      return state.selectedLocation
    }

    const presentLocation = JSON.parse(localStorage.getItem("loc"))

    if (presentLocation) {
      dispatch({ payload: presentLocation, type: "UPDATE_LOCATION" })
      return presentLocation
    } else {
      return null
    }
  }

  const logIn = (credentials) => {
    setIsLoadingRequest(true)

    mainAxios
      .post("/auth/sign_in", credentials)
      .then((response) => {
        setIsLoadingRequest(false)
        const userData = getUserObject(response.data.data)
        dispatch({
          payload: userData,
          type: "LOGIN_USER",
        })
        dispatch({
          payload: userData.email,
          type: "SET_USER",
        })
        dispatch({
          payload: userData.plan,
          type: "UPDATE_PLAN",
        })
        const { "access-token": accessToken, client, uid } = response.headers
        localStorage.setItem("client", client)
        localStorage.setItem("uid", uid)
        localStorage.setItem("access-token", accessToken)
        localStorage.setItem("user", JSON.stringify(userData))
        setAuthToken(client, uid, accessToken)
        if (userData.plan === null) {
          navigate("/choose-plan")
        } else {
          navigate("/home")
        }
        toastMessage(LOGIN_SUCCESS, TYPE_SUCCESS)
      })
      .catch((error) => {
        setIsLoadingRequest(false)
        setError([error])
      })
  }

  const googleAuth = (params) => {
    setIsLoadingRequest(true)

    mainAxios
      .post("/users/sign-in/socially", params)
      .then((response) => {
        setIsLoadingRequest(false)

        const userData = getUserObject(response.data)
        dispatch({
          payload: userData,
          type: "LOGIN_USER",
        })
        dispatch({
          payload: userData.email,
          type: "SET_USER",
        })
        dispatch({
          payload: userData.plan,
          type: "UPDATE_PLAN",
        })

        const { "access-token": accessToken, client, uid } = response.headers
        localStorage.setItem("client", client)
        localStorage.setItem("uid", uid)
        localStorage.setItem("access-token", accessToken)
        localStorage.setItem("user", JSON.stringify(userData))
        setAuthToken(client, uid, accessToken)

        if (userData.plan === null) {
          navigate("/choose-plan")
        } else {
          navigate("/home")
        }
      })
      .catch((error) => {
        setIsLoadingRequest(false)
        setError([error])
      })
  }

  const logOut = () => {
    setIsLoadingRequest(true)

    const localUser = localStorage.getItem("user")

    if (state.isAuthenticated && localUser) {
      ensureAuthToken()

      mainAxios
        .delete("/auth/sign_out")
        .then((response) => {
          localStorage.removeItem("client")
          localStorage.removeItem("uid")
          localStorage.removeItem("access-token")
          localStorage.removeItem("user")
          removeAuthToken()
          const loc = JSON.parse(localStorage.getItem("loc"))
          if (loc) navigate("/")
          else navigate("/")
          dispatch({ type: "LOGOUT_USER" })
          setIsLoadingRequest(false)
          toastMessage(LOGOUT_SUCCESS, TYPE_SUCCESS)
        })
        .catch((error) => {
          setIsLoadingRequest(false)
          setError([error])
        })
    }
  }

  const autoLogout = () => {
    setTimeout(logOut, 5000)
  }

  const subscribePlan = (token) => {
    setIsLoadingRequest(true)

    if (state.selectedPlan !== "") {
      let data = {}
      if (token !== null) {
        data = {
          subscription_plan_id: state.selectedPlan,
          token: token,
        }
      } else {
        data = {
          subscription_plan_id: state.selectedPlan,
        }
      }
      mainAxios
        .post("/stripe/subscribe", data)
        .then((response) => {
          const userTemp = getUserObject(response.data.stripe_information.user)
          dispatch({
            payload: userTemp,
            type: "UPDATE_USER",
          })
          localStorage.setItem("user", JSON.stringify(userTemp))
          dispatch({ payload: userTemp.plan, type: "UPDATE_PLAN" })
          if (
            token !== null &&
            response.data.stripe_information.success !== false
          ) {
            navigate("/register-success")
          } else if (
            token !== null &&
            response.data.stripe_information.success === false
          ) {
            toastMessage(response.data.stripe_information.message, TYPE_ERROR)
          }
          setIsLoadingRequest(false)
        })
        .catch((error) => {
          setIsLoadingRequest(false)
          setError([error])
        })
    } else {
      toastMessage("Please choose a plan to proceed", TYPE_ERROR)
    }
  }

  const changePlan = () => {
    if (state.user.plan !== null) {
      ensureAuthToken()
      if (checkIsAuthenticated()) {
        toastMessage("Plan changed", TYPE_SUCCESS)
        subscribePlan(null)
      }
    }
  }

  const forgotPassword = (email) => {
    setIsLoadingRequest(true)

    mainAxios
      .post("/auth/password", {
        email: email,
        redirect_url: `${process.env.GATSBY_REDIRECT_URL}/reset-password`,
      })
      .then((response) => {
        setIsLoadingRequest(false)
        dispatch({ payload: email, type: "FORGOT_PASS" })
        if (response.data.success) {
          navigate("/forgot-password-confirm")
        }
      })
      .catch((error) => {
        setIsLoadingRequest(false)
        setError([error])
      })
  }

  const resetPassword = (dataParams, password) => {
    setIsLoadingRequest(true)

    const config = {
      headers: dataParams,
    }
    const data = {
      password: password,
      password_confirmation: password,
    }
    mainAxios
      .put("/auth/password", data, config)
      .then((response) => {
        setIsLoadingRequest(false)
        if (response.request.status === 200) {
          navigate("/reset-password-confirm")
        }
      })
      .catch((error) => {
        setError([error])
        setIsLoadingRequest(false)
      })
  }

  const checkIsAuthenticated = () => {
    const presentUser = JSON.parse(localStorage.getItem("user"))

    if (presentUser) {
      dispatch({
        payload: presentUser,
        type: "REFRESH_PAGE",
      })
      dispatch({
        payload: presentUser.plan,
        type: "UPDATE_PLAN",
      })
      return true
    } else {
      dispatch({
        type: "LOGOUT_USER",
      })
      logOut()
      return false
    }
  }

  const setPlanList = (plans) => {
    dispatch({ payload: plans, type: "PLAN_LIST_SET" })
  }

  const setPlan = (plan) => {
    dispatch({ payload: plan, type: "SET_PLAN" })
  }

  const setIsLoadingRequest = (isLoading) => {
    dispatch({ payload: isLoading, type: "TOGGLE_LOADING" })
  }

  return (
    <AuthenticationContext.Provider
      value={{
        autoLogout,
        changePlan,
        checkIsAuthenticated,
        dispatch,
        email: state.email,
        ensureAuthToken,
        error: state.error,
        forgotPassword,
        getLocation,
        googleAuth,
        isAuthenticated: state.isAuthenticated,
        isLoadingRequest: state.isLoadingRequest,
        isUserSet: state.isUserSet,
        logIn,
        logOut,
        plans: state.plans,
        resetPassword,
        setError,
        setIsLoadingRequest,
        setLocation,
        setPlan,
        setPlanList,
        signUp,
        subscribePlan,
        updateUser,
        user: state.user,
        userSelectedPlan: state.userSelectedPlan,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  )
}

AuthenticationProvider.propTypes = {
  children: PropTypes.node.isRequired,
}
