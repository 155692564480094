import * as Sentry from "@sentry/react"
import axios from "axios"
import NProgress from "nprogress"

import { toastMessage, TYPE_ERROR } from "../components/UI/Toast"
import { BASE_URL_API } from "./api-constants"

import "nprogress/nprogress.css"

const scope = new Sentry.Scope()
scope.setTag("section", "API ERROR")

const mainAxios = axios.create({
  baseURL: process.env.GATSBY_BASE_URL_API || BASE_URL_API,
  headers: { "Content-Type": "application/json" },
  timeout: 15000,
})

export const setAuthToken = (client, uid, accessToken) => {
  mainAxios.defaults.headers.common.client = client
  mainAxios.defaults.headers.common.uid = uid
  mainAxios.defaults.headers.common["access-token"] = accessToken
}

export const isAuthTokenEmpty = () => {
  if (
    mainAxios.defaults.headers.common.client &&
    mainAxios.defaults.headers.common.uid &&
    mainAxios.defaults.headers.common["access-token"]
  ) {
    return false
  }
  return true
}

export const removeAuthToken = () => {
  mainAxios.defaults.headers.common.client = ""
  mainAxios.defaults.headers.common.uid = ""
  mainAxios.defaults.headers.common["access-token"] = ""
}

mainAxios.interceptors.response.use(
  (response) => {
    NProgress.done()
    return response
  },
  (error) => {
    NProgress.done()
    let message = ""
    if (error.response?.data?.errors?.full_messages) {
      message = error.response?.data?.errors?.full_messages.join("\n")
      toastMessage(message, TYPE_ERROR)
    } else if (error.response?.data?.errors) {
      message = error.response?.data.errors.join("")
      toastMessage(message, TYPE_ERROR)
    } else if (error.response?.data?.error) {
      message = error.response?.data?.error
      toastMessage(message, TYPE_ERROR)
    }
    if (process.env.GATSBY_SENTRY_DSN) {
      const tempError = new Error()
      tempError.message = message
      tempError.name = "API RESPONSE ERROR"
      Sentry.captureException(tempError, scope)
    }
    return error
  }
)

mainAxios.interceptors.request.use(
  (request) => {
    NProgress.start()
    return request
  },
  (error) => {
    NProgress.done()
    if (process.env.GATSBY_SENTRY_DSN) {
      const tempError = new Error(error)
      tempError.name = "API REQUEST ERROR"
      Sentry.captureException(tempError, scope)
    }
    return error
  }
)

export default mainAxios
