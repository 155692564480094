const ApplicationReducer = (state, action) => {
  const fetchedOrders = state.orders
  let updatedOrders = []

  let dryCleaningOrder = {}
  let washingOrder = {}
  let washAndPressOrder = {}
  let pressOrder = {}

  switch (action.type) {
    case "PENDING_FETCH_ORDERS":
      action.payload.forEach((order) => {
        if (order.order_type === "dry_cleaning") {
          dryCleaningOrder = order
        } else if (order.order_type === "washing") {
          washingOrder = order
        } else if (order.order_type === "wash_and_press") {
          washAndPressOrder = order
        } else if (order.order_type === "press") {
          pressOrder = order
        }
      })
      return {
        ...state,
        dryCleaningOrder: dryCleaningOrder,
        pressOrder,
        washAndPressOrder,
        washingOrder: washingOrder,
      }

    case "SET_FETCH_ORDERS":
      return {
        ...state,
        orders: action.payload,
      }

    case "SET_SELECTED_ORDERS":
      return {
        ...state,
        selectedOrders: action.payload,
      }

    case "SET_SELECTED_TYPES":
      return {
        ...state,
        selectedTypes: action.payload,
      }

    case "COLLECT_ORDER":
      updatedOrders = fetchedOrders.map((order) => {
        if (order.id === action.id) {
          order = action.payload
        }
        return order
      })

      return {
        ...state,
        orders: updatedOrders,
      }

    case "LOGOUT":
      return {
        dryCleaningOrder: {},
        orders: [],
        pressOrder: {},
        selectedOrders: [],
        washAndPressOrder: {},
        washingOrder: {},
      }

    default:
      return state
  }
}

export default ApplicationReducer
