exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-components-landing-page-landing-new-js": () => import("./../../../src/components/LandingPage/LandingNew.js" /* webpackChunkName: "component---src-components-landing-page-landing-new-js" */),
  "component---src-components-landing-page-qr-landing-js": () => import("./../../../src/components/LandingPage/QrLanding.js" /* webpackChunkName: "component---src-components-landing-page-qr-landing-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-choose-plan-js": () => import("./../../../src/pages/choose-plan.js" /* webpackChunkName: "component---src-pages-choose-plan-js" */),
  "component---src-pages-credit-card-payment-js": () => import("./../../../src/pages/credit-card-payment.js" /* webpackChunkName: "component---src-pages-credit-card-payment-js" */),
  "component---src-pages-drop-off-confirm-js": () => import("./../../../src/pages/drop-off-confirm.js" /* webpackChunkName: "component---src-pages-drop-off-confirm-js" */),
  "component---src-pages-drop-off-js": () => import("./../../../src/pages/drop-off.js" /* webpackChunkName: "component---src-pages-drop-off-js" */),
  "component---src-pages-forgot-password-confirm-js": () => import("./../../../src/pages/forgot-password-confirm.js" /* webpackChunkName: "component---src-pages-forgot-password-confirm-js" */),
  "component---src-pages-forgot-password-js": () => import("./../../../src/pages/forgot-password.js" /* webpackChunkName: "component---src-pages-forgot-password-js" */),
  "component---src-pages-get-started-js": () => import("./../../../src/pages/get-started.js" /* webpackChunkName: "component---src-pages-get-started-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-home-options-js": () => import("./../../../src/pages/home-options.js" /* webpackChunkName: "component---src-pages-home-options-js" */),
  "component---src-pages-location-access-js": () => import("./../../../src/pages/location-access.js" /* webpackChunkName: "component---src-pages-location-access-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-pick-up-confirm-js": () => import("./../../../src/pages/pick-up-confirm.js" /* webpackChunkName: "component---src-pages-pick-up-confirm-js" */),
  "component---src-pages-pick-up-js": () => import("./../../../src/pages/pick-up.js" /* webpackChunkName: "component---src-pages-pick-up-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-register-success-js": () => import("./../../../src/pages/register-success.js" /* webpackChunkName: "component---src-pages-register-success-js" */),
  "component---src-pages-reset-password-confirm-js": () => import("./../../../src/pages/reset-password-confirm.js" /* webpackChunkName: "component---src-pages-reset-password-confirm-js" */),
  "component---src-pages-reset-password-js": () => import("./../../../src/pages/reset-password.js" /* webpackChunkName: "component---src-pages-reset-password-js" */),
  "component---src-pages-settings-js": () => import("./../../../src/pages/settings.js" /* webpackChunkName: "component---src-pages-settings-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../../../src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */)
}

