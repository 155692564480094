import { loadStripe } from "@stripe/stripe-js"

export const getUserObject = (user) => {
  return {
    can_place_order: user.can_place_order,
    email: user.email,
    firstName: user.first_name,
    id: user.id,
    lastName: user.last_name,
    mobile: user.mobile,
    plan: user.plan,
    postCode: user.postcode,
    provider: user.provider,
    type: user.type,
    uid: user.uid,
  }
}

export const loadStripePromise = () => {
  const stripePromise = loadStripe(`${process.env.GATSBY_STRIPE_KEY}`)
  return stripePromise
}

export const isFormEmpty = (values) => {
  let isEmpty = false
  for (const key in values) {
    if (values[key] === true) isEmpty = true
  }
  return isEmpty
}
