import React from "react"

import { StaticQuery, graphql } from "gatsby"

import * as Sentry from "@sentry/react"
import { Integrations } from "@sentry/tracing"
import PropTypes from "prop-types"

import SEO from "../components/seo"
import Toast from "../components/UI/Toast"

import "../assets/scss/main.scss"

import "./index.scss"

Sentry.init({
  dsn: `${process.env.GATSBY_SENTRY_DSN || ""}`,
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
})

const Layout = ({ location, children }) => {
  const isPickup = location.pathname.includes("pick-up")

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={(data) => {
        return (
          <>
            <SEO title="Washroom" />
            <Sentry.ErrorBoundary fallback={"An error has occurred"}>
              <main>
                <Toast />
                {children}
                {isPickup && (
                  <div className="bottom-email-link">
                    <a href="mailto:hello@wrlaundry.com">hello@wrlaundry.com</a>
                  </div>
                )}
              </main>
            </Sentry.ErrorBoundary>
          </>
        )
      }}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object.isRequired,
}

export default Layout
