const AuthenticationReducer = (state, action) => {
  switch (action.type) {
    case "SET_ERROR":
      return {
        ...state,
        error: action.payload,
      }
    case "SET_USER":
      return {
        ...state,
        email: action.payload,
        isAuthenticated: true,
        isUserSet: true,
      }
    case "LOGOUT_USER":
      return {
        ...state,
        email: "",
        error: [],
        isAuthenticated: false,
        isUserSet: false,
        plans: [],
        selectedLocation: {},
        selectedPlan: "",
        user: {},
        userSelectedPlan: "",
      }
    case "LOGIN_USER":
      return {
        ...state,
        error: [],
        isAuthenticated: true,
        isUserSet: false,
        user: action.payload,
      }
    case "FORGOT_PASS":
      return {
        ...state,
        email: action.payload,
      }
    case "UPDATE_USER":
      return {
        ...state,
        user: action.payload,
      }
    case "REFRESH_PAGE":
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload,
      }
    case "PLAN_LIST_SET":
      return {
        ...state,
        plans: action.payload,
      }
    case "SET_PLAN":
      return {
        ...state,
        selectedPlan: action.payload,
      }
    case "UPDATE_PLAN":
      return {
        ...state,
        userSelectedPlan: action.payload,
      }
    case "UPDATE_LOCATION":
      return {
        ...state,
        selectedLocation: action.payload,
      }
    case "TOGGLE_LOADING":
      return {
        ...state,
        isLoadingRequest: action.payload,
      }
    default:
      return state
  }
}

export default AuthenticationReducer
