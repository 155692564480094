import React, { createContext, useReducer, useState } from "react"

import { navigate } from "gatsby"

import PropTypes from "prop-types"

import { AuthenticationContext } from "../"
import { toastMessage, TYPE_SUCCESS } from "../../components/UI/Toast"
import mainAxios from "../../utils/axiosConfig"
import { PICKUP_CONFIRM } from "../../utils/constants"
import ApplicationReducer from "./ApplicationReducer"

const initialState = {
  dryCleaningOrder: {},
  orders: [],
  pressOrder: {},
  selectedOrders: [],
  selectedTypes: [],
  washAndPressOrder: {},
  washingOrder: {},
}

export const ApplicationContext = createContext(initialState)

export const ApplicationProvider = ({ children }) => {
  const [state, dispatch] = useReducer(ApplicationReducer, initialState)
  const [isLoading, setIsLoading] = useState(false)
  const [availableLockers, setAvailableLockers] = useState(null)
  const {
    setIsLoadingRequest,
    getLocation,
    ensureAuthToken,
    setError,
    checkIsAuthenticated,
  } = React.useContext(AuthenticationContext)

  // const setLocation = (location) => {
  //   const locationObject = {
  //     latitude: location.coords.latitude,
  //     longitude: location.coords.longitude,
  //   }
  //   localStorage.setItem("isAccessDenied", false)
  //   localStorage.setItem("location", JSON.stringify(locationObject))
  //   navigate("/home")
  // }

  // React.useEffect(() => {
  //   if (navigator.permissions && navigator.permissions.query) {
  //     navigator.permissions
  //       .query({ name: "geolocation" })
  //       .then((permissionStatus) => {
  //         permissionStatus.onchange = function () {
  //           if (this.state === "granted") {
  //             window.navigator.geolocation.getCurrentPosition(setLocation)
  //           } else if (this.state === "denied") {
  //             localStorage.removeItem("location")
  //             localStorage.setItem("isAccessDenied", true)
  //             navigate("/location-access")
  //           } else {
  //             localStorage.removeItem("location")
  //             localStorage.setItem("isAccessDenied", false)
  //             window.navigator.geolocation.getCurrentPosition(setLocation, () =>
  //               localStorage.setItem("isAccessDenied", true)
  //             )
  //             navigate("/home")
  //           }
  //         }
  //       })
  //   } else {
  //     window.navigator.geolocation.getCurrentPosition(setLocation)
  //   }
  // })

  React.useEffect(() => {
    let selectedTypes = localStorage.getItem("selectedTypes")
    let dropOffOrders = localStorage.getItem("dropOffOrders")

    if (selectedTypes && dropOffOrders) {
      selectedTypes = JSON.parse(selectedTypes)
      dropOffOrders = JSON.parse(dropOffOrders)
      dispatch({
        payload: dropOffOrders,
        type: "SET_FETCH_ORDERS",
      })
      dispatch({
        payload: dropOffOrders,
        type: "PENDING_FETCH_ORDERS",
      })
      setSelectedTypes(selectedTypes)
    }
  }, [])

  const newOrder = (statuses) => {
    ensureAuthToken()
    const slug = getLocation().slug
    const orderTypesArray = state.orders.map((order) => order.order_type)
    const tempStatuses = statuses.filter(
      (status) => !orderTypesArray.includes(status)
    )
    const location = JSON.parse(localStorage.getItem("location"))
    const orderPromises = tempStatuses.map((status) => {
      const params = {
        latitude: location.latitude,
        longitude: location.longitude,
        order_type: status,
        slug,
      }
      return mainAxios.post("/orders", params)
    })

    return orderPromises
  }

  const fetchOrders = (status, shouldNavigate, selectedTypes) => {
    ensureAuthToken()
    setIsLoading(true)
    const id = getLocation().id
    const slug = getLocation().slug
    const upperCaseStatus = status.toUpperCase()
    if (checkIsAuthenticated()) {
      const params = {
        location_id: id,
        slug,
        status: status,
      }
      mainAxios
        .get("/orders", {
          params: params,
        })
        .then((res) => {
          const data = JSON.parse(res.request.response)
          if (data.orders && data.orders.length > 0) {
            dispatch({
              payload: data.orders,
              type: "SET_FETCH_ORDERS",
            })
            dispatch({
              payload: data.orders,
              type: `${upperCaseStatus}_FETCH_ORDERS`,
            })
            if (shouldNavigate && selectedTypes) {
              setSelectedTypes(selectedTypes)
              localStorage.setItem("dropOffOrders", JSON.stringify(data.orders))
              navigate("/drop-off")
            }
          }
          setIsLoading(false)
        })
      setIsLoadingRequest(false)
    }
  }

  const confirmOrder = (params) => {
    ensureAuthToken()
    if (checkIsAuthenticated()) {
      return mainAxios.put("/orders/status_change", params)
    }
  }

  const collectOrder = (id) => {
    ensureAuthToken()
    setIsLoadingRequest(true)

    if (checkIsAuthenticated()) {
      const params = {
        order_ids: [id],
      }
      mainAxios
        .put("/orders/status_change", params)
        .then((res) => {
          const data = JSON.parse(res.request.response)
          dispatch({
            id: id,
            payload: data.order,
            type: "COLLECT_ORDER",
          })
          setIsLoadingRequest(false)
          toastMessage(PICKUP_CONFIRM, TYPE_SUCCESS)
        })
        .catch((error) => {
          setError([error])
          setIsLoadingRequest(false)
        })
    }
  }
  const abandonOrders = (orders) => {
    ensureAuthToken()
    if (checkIsAuthenticated()) {
      const cancelPromises = orders.map((order) => {
        const cancelDetails = {
          abandoned: true,
          order_ids: [order.id],
        }

        return mainAxios.put("/orders/status_change", cancelDetails)
      })

      Promise.all(cancelPromises).then(() => {
        resetOrders()
        navigate(-1)
      })
    }
  }

  const cancelPendingOrdersDropOff = () => {
    ensureAuthToken()
    if (checkIsAuthenticated()) {
      const cancelPromises = state.orders.map((order) => {
        if (!order.customer_drop_off_locker_id) {
          const cancelDetails = {
            abandoned: true,
            order_ids: [order.id],
          }
          return mainAxios.put("/orders/status_change", cancelDetails)
        }
        return null
      })

      Promise.all(cancelPromises)
      resetOrders()
    }
  }

  const resetOrders = () => {
    dispatch({ type: "LOGOUT" })
  }

  const getAvailableLockers = () => {
    setIsLoadingRequest(true)
    const slug = getLocation().slug
    mainAxios
      .get(`/locations/${slug}/availability`)
      .then((res) => {
        setAvailableLockers(res.data.lockers_available)
        setIsLoadingRequest(false)
      })
      .catch((error) => {
        setError([error])
        setIsLoadingRequest(false)
      })
  }

  const setSelectedOrders = (selectedTypes) => {
    const tempSelectedOrders = []
    selectedTypes.forEach((type) => {
      switch (type) {
        case "washing":
          tempSelectedOrders.push(state.washingOrder)
          break
        case "dry_cleaning":
          tempSelectedOrders.push(state.dryCleaningOrder)
          break
        case "wash_and_press":
          tempSelectedOrders.push(state.washAndPressOrder)
          break
        case "press":
          tempSelectedOrders.push(state.pressOrder)
          break
      }
    })
    dispatch({ payload: tempSelectedOrders, type: "SET_SELECTED_ORDERS" })
  }

  const setSelectedTypes = (selectedTypes) => {
    dispatch({ payload: selectedTypes, type: "SET_SELECTED_TYPES" })
  }

  return (
    <ApplicationContext.Provider
      value={{
        ...state,

        abandonOrders,
        availableLockers,
        cancelPendingOrdersDropOff,
        collectOrder,
        confirmOrder,
        dispatch,
        dryCleaningOrder: state.dryCleaningOrder,
        fetchOrders,
        getAvailableLockers,
        isLoading,
        newOrder,
        orders: state.orders,
        resetOrders,
        setIsLoading,
        setSelectedOrders,
        washingOrder: state.washingOrder,
      }}
    >
      {children}
    </ApplicationContext.Provider>
  )
}

ApplicationProvider.propTypes = {
  children: PropTypes.node.isRequired,
}
